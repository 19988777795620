import React, {useState, useMemo, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {AppBar, Toolbar, Container, Grid, Box, Button, IconButton, Drawer, Divider, List, ListItem, ListItemButton, ListItemText} from '@mui/material';
import  Menu from '@mui/icons-material/Menu';
import { useScrollPosition, IScrollProps } from "hooks/useScrollPosition";
import Constants from "../../Constants";
import {ITab} from "../../types";

import logo from "../../assets/svg/logo_black.svg"

import styles from "./Header.style"

const Header = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const classes = styles();

  const [drawerOpen, setDrawerOpen] = useState(false)
  const handleDrawerToggle = () => { setDrawerOpen(!drawerOpen) }

  const [hideOnScroll, setHideOnScroll] = useState(true)

  // Hide on scroll behavior
  const scrollEffect = ({ prevPos, currPos }:IScrollProps) => {
    const isShow = currPos.y > -70 || currPos.y > prevPos.y
    if (isShow !== hideOnScroll) setHideOnScroll(isShow)
  }
  const scrollContainer = useRef(window.document.body)
  useScrollPosition( scrollEffect, [hideOnScroll], scrollContainer, false, 300 )


  return (
    <>
      <AppBar position="sticky" component="nav" hidden={!hideOnScroll} className={ classes.headerRoot }>
        <Toolbar className={ classes.headerToolbar }>
          <Box onClick={()=>navigate('/')} className={ classes.headerLogo }>
            <img src={logo} alt="Kemtai" />
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            { Constants.tabs.map((tab: ITab)=> (
              <Button key={tab.path}
                      onClick={()=>navigate(tab.path)}
                      className={ location.pathname.startsWith(tab.path) ? 'active' : '' }>
                {tab.title }
              </Button>
            ))}
          </Box>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { md: 'none' } }} >
            <Menu />
          </IconButton>

        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          anchor="right"
          container={ undefined }
          variant="temporary"
          open={ drawerOpen }
          onClose={ handleDrawerToggle }
          ModalProps={{ keepMounted: true }}
          sx={{ display: { xs: 'block', md: 'none' } }}
          className={ classes.navSideMenu } >

          <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Box onClick={()=>navigate('/')} className="sidenav-logo">
              <img src={logo} alt="Kemtai" />
            </Box>
            <Divider />
            <List>
              {Constants.tabs.map((tab) => (
                <ListItem key={tab.path} disablePadding>
                  <ListItemButton onClick={()=>navigate(tab.path)}
                                  className={ location.pathname.startsWith(tab.path) ? 'active' : '' }>
                    <ListItemText primary={tab.title} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>

        </Drawer>
      </Box>
    </>
  )

}

export default Header;

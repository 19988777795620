import {observable, makeObservable, toJS} from 'mobx';
import { createTheme } from '@mui/material/styles';
import { themeLightOptions, themeDarkOptions } from "./theme"


export enum THEMES {
  light = "light",
  dark = "dark"
}

class themeManager {

  name: THEMES | null = null
  theme = createTheme(themeDarkOptions)
  defaultTheme:THEMES = THEMES.dark

  constructor() {
    makeObservable(this, {
      name: observable,
      theme: observable,
    })
    this.name = THEMES.dark
  }

  setTheme(name:THEMES){
    this.name = name
    this.theme = createTheme(this.getOptions(name))
  }

  getOptions(name:THEMES){
    return name === THEMES.light ? themeLightOptions : themeDarkOptions
  }

  getTheme(name:THEMES|false = false, rtl: boolean = false){
    if(name){
      return toJS(createTheme({ ...this.getOptions(name), direction: rtl ? "rtl" : "ltr" }))
    }
    return toJS(createTheme({ ...themeDarkOptions, direction: rtl ? "rtl" : "ltr" }))
  }

}

export default new themeManager();
import merge from "lodash/merge";
import { ThemeOptions } from "@mui/material/styles";
import {
  lightBlue,
  indigo,
  red,
  grey,
} from "@mui/material/colors";

const lightText = {
  primary: "rgb(17, 24, 39)",
  secondary: "rgb(107, 114, 128)",
  disabled: "rgb(149, 156, 169)",
};

const darkText = {
  primary: "rgb(255,255,255)",
  secondary: "rgb(229, 231, 235)",
  disabled: "rgb(156, 163, 175)",
};

const baseThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  shape: {
    borderRadius: 12,
  },
  typography: {
    h1: {
      fontWeight: 700,
      fontFamily: "Gilroy-ExtraBold, Gilroy-Heavy, Arial, Helvetica, sans-serif",
      fontSize: "2rem",
      letterSpacing: "1px",
    },
    h2: {
      fontWeight: 700,
      fontFamily: "Gilroy-ExtraBold, Gilroy-Heavy, Arial, Helvetica, sans-serif",
      fontSize: "1.6rem",
      letterSpacing: "1px",
    },
    h3: {
      fontWeight: 700,
      fontFamily: "Gilroy-ExtraBold, Gilroy-Heavy, Arial, Helvetica, sans-serif",
      fontSize: "1.3rem",
      letterSpacing: ".6px",
    },
    h4: {
      fontWeight: 700,
      fontFamily: "Gilroy-ExtraBold, Gilroy-Heavy, Arial, Helvetica, sans-serif",
      fontSize: "1.15rem",
      letterSpacing: ".6px",
    },
    h5: {
      fontWeight: 700,
      fontFamily: "Gilroy-ExtraBold, Gilroy-Heavy, Arial, Helvetica, sans-serif",
      fontSize: "1rem",
      letterSpacing: ".3px",
    },
    h6: {
      fontWeight: 400,
      fontFamily: "Roboto-Regular,Arial,Helvetica,sans-serif",
      fontSize: "0.875rem",
      letterSpacing: ".3px",
    },
    fontFamily: "Gilroy-Light, Arial, Helvetica, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 25,
          fontFamily: "Gilroy-ExtraBold, Arial, Helvetica, sans-serif",
          letterSpacing: ".6px",
          textTransform: "unset",
        },
        text: {
          fontFamily: 'Gilroy-Light,Arial,Helvetica,sans-serif',
          textDecoration: 'underline',
          fontSize: '1rem',
          padding: '6px 18px',
          '&:hover': {
            textDecoration: 'underline',
          }
        },
        containedPrimary: {
          backgroundImage:
            "linear-gradient(84deg, #ff0 -21%, #b3fe00 96%, #aafe00 0)",
          fontWeight: 700,
          ":hover": {
            opacity: 0.75,
          },
          ":disabled": {
            color: "#8a8a8a",
            opacity: 0.5,
          },
        },
        sizeSmall: {
          padding: "8px 16px",
          lineHeight: "1.2em",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          textAlign: "left"
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: "4px",
          borderRadius: "2px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "Gilroy-ExtraBold, Arial, Helvetica, sans-serif",
          textTransform: "capitalize",
          fontSize: "1.375rem",
          letterSpacing: ".5px",
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "25px",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily: "Gilroy-ExtraBold, Arial, Helvetica, sans-serif",
          fontSize: "1.5rem",
        },
        colorDefault: {
          backgroundImage:
            "linear-gradient(87deg, #ffff00 4%, #b3fe00 96%, #aafe00 96%)",
          color: "#000000",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: '9px',
          width: '50px',
          height: '34px',
          "& .MuiSwitch-switchBase": {
            padding: '10px',
            "&.Mui-checked": {
              transform: 'translateX(16px)',
              "& + .MuiSwitch-track": {
                background: 'linear-gradient(88deg, #ff0 -22%, #b3fe00 98%, #aafe00 98%)',
                opacity: 1,
              },
            },
            "&.Mui-disabled": {
              "& + .MuiSwitch-track": {
                opacity: .2,
              },
            },
            "& .MuiSwitch-thumb": {
              width: '14px',
              height: '14px',
              backgroundColor: '#000'
            }
          },

          "& .MuiSwitch-track": {
            borderRadius: '8px'
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          letterSpacing: ".5px",
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 10001,
        }
      }
    },
  },
};

export const lightOptions: ThemeOptions = {
  palette: {
    mode: "light",
    text: lightText,
    common: {
      black: "rgb(17, 24, 39)",
      white: "rgb(255, 255, 255)",
    },
    primary: indigo,
    secondary: {
      light: lightBlue[100],
      main: lightBlue[500],
      dark: lightBlue[900],
    },
    background: {
      paper: "#FFFFFF",
      default: "#f6f7f9",
    },
    error: red,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: '#000',
        },
        outlinedPrimary: {
          color: '#000',
          borderColor: 'rgba(0,0,0,.7)',
          ":hover": {
            borderColor: 'rgba(0,0,0,1)',
            backgroundColor: 'rgba(0,0,0,.05)'
          },
        }
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          color: "#000",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#808d9b",
          "&.Mui-focused": {
            color: "#808d9b",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#cbd4dc",
        },
        input: {
          color: "#808d9b",
          "&:-webkit-autofill": {
            boxShadow: "0 0 0 100px #fff inset",
            textFillColor: "#808d9b",
            borderRadius: "12px",
          },
        },
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#cbd4dc",
              borderWidth: 2,
            },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff'
        }
      }
    }
  },
};

export const darkOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    text: darkText,
    primary: {
      light: grey[300],
      main: grey[500],
      dark: grey[700],
    },
    secondary: {
      light: lightBlue[100],
      main: lightBlue[500],
      dark: lightBlue[900],
    },
    background: {
      default: "#1b1c21",
      paper: "rgba(15,16,22,0.7)",
    },
    error: red,
  },

  components: {
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          fontFamily: "Gilroy-Light, Arial, Helvetica, sans-serif",
          color: "rgba(255,255,255,.85)",
          borderColor: "rgba(255,255,255,.85)",
          "&:hover, &:active": {
            color: "#fff",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#000",
          color: "#fff",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#0F1016",
          backgroundImage: "unset",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          backgroundColor: "#131319",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "#131319",
          backgroundImage: "unset",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: "#131319",
          backgroundImage: "unset",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "transparent",
          "&:before": {
            content: "''",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            height: "4px",
            width: "50%",
            minWidth: "35px",
            backgroundColor: "#fff",
            borderRadius: "2px",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "rgba(255,255,255,.7)",
          "&.Mui-selected": {
            color: "#fff",
          },
        },
      },
    },
  },
};

export const themeLightOptions: ThemeOptions = merge(
  lightOptions,
  baseThemeOptions
);

export const themeDarkOptions: ThemeOptions = merge(
  darkOptions,
  baseThemeOptions
);

export const darkScrollbar = () => ({
  "&::-webkit-scrollbar-track": {
		borderRadius: "8px",
		backgroundColor: "rgba(0, 0, 0, 0.1)"
	},
	"&::-webkit-scrollbar": {
		width: "8px",
		backgroundColor: "rgba(0, 0, 0, 0.1)"
	},
	"&::-webkit-scrollbar-thumb": {
		borderRadius: "10px",
		backgroundColor: "#000"
	}
});

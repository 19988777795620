import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) =>
	createStyles({
    headerRoot: {
      "&.MuiPaper-root": {
        background: '#fff',
        color: '#000',
        transition: 'all .3s ease-in-out',
        "&[hidden]":{
          transform: 'translateY(-105%)'
        }
      }
    },
    headerLogo: {
      cursor: 'pointer'
    },
    headerToolbar: {
      "&.MuiToolbar-root": {
        justifyContent: 'space-between',
        minHeight: '56px',
        [theme.breakpoints.down("md")]: {
					minHeight: '42px',
				},
        "& .MuiButtonBase-root": {
          textDecoration: 'none',
          fontFamily: theme.typography.h1.fontFamily,
          color: '#000',
          fontSize: '14px',
          opacity: .65,

          "& .MuiTouchRipple-root": {
            background: 'transparent'
          },

          "&:after": {
            content: "''",
            position: 'absolute',
            bottom: '0px',
            left: '20px',
            right: '20px',
            height: '4px',
            borderRadius: '2px',
            maxWidth: 0,
            background: '#C8D2DA',
            transition: 'all .25s linear'
          },

          "&.active": {
            opacity: 1,

            "&:after": {
              maxWidth: '1000px'
            }
          },

          "&:hover": {
            opacity: 1,
            backgroundColor: 'transparent',
            "& .MuiTouchRipple-root": {},
          }
        }
      }
    },

    navSideMenu: {
      "&.MuiDrawer-root": {
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 270,
          backgroundColor: '#fff',
          color: '#000',

          "& .sidenav-logo": {
            padding: '15px 0',
            cursor: 'pointer',
          },

          "& .MuiDivider-root": {
            borderColor: 'rgba(0,0,0, 0.12)',
          },
          "& .MuiListItem-root": {
            //marginBottom: '10px',
          },
          "& .MuiButtonBase-root": {
            textAlign: 'center',
            "&.active": {
              "& .MuiTypography-root": {
                fontFamily: theme.typography.h1.fontFamily,
              },
              "&:after": {
                content: "''",
                position: 'absolute',
                bottom: '5px',
                left: '20px',
                right: '20px',
                height: '4px',
                borderRadius: '2px',
                background: '#C8D2DA',
              }
            }
          }
        }
      }
    },

    wrapper: {
      minHeight: "100%",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'left bottom',
			//background: 'linear-gradient(0deg, rgba(200,210,218,1) 0%, rgba(200,210,218,1) 40%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%)'
    }
	})
);
